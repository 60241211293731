.logo-sidebar img {
    /* background-image: url("../assets/hermoso_logo.png"); */
    width: 19rem;
    height: 6rem;
}

.logo-sidebar {
    display: flex;
    justify-content: center;
    margin-bottom: 35%;
    margin-top: 6%;

}

.logo-flex {
    width: 100%;
}

.main-div-sidebar {
    width: 100%;
    min-height: 100vh;
    background-color: #FCFCFF;
    border-right: 3px solid #FCFCFF;
}

.side-bar-icon {
    font-size: 2rem;
    color: #C7C7C7;
}


.icon-para {
    font-size: 1.4rem;
    font-weight: bold;
    color: #C7C7C7;
    margin-top: 4px;
    margin-left: 10px;
}


.top {
    margin-top: 2px;
}

.bottom {
    margin-top: 5px;
}

.text-icon {
    display: flex;
    width: 90%;
    margin: 10px auto;
    border-radius: 4px;
    padding: 8px;
}

.text-icon-sel {
    display: flex;
    width: 90%;
    margin: 10px auto;
    border-radius: 4px;
    padding: 8px;
    background-color: #F0F7FF;
}

.text-icon:hover {
    background-color: #F0F7FF;
    cursor: pointer;
}

.text-icon:hover .icon-para {
    color: #0063F5;
}

.text-icon:hover .side-bar-icon {
    color: #0063F5;
}

.side-bar-icon-sel {
    color: #0063F5;
    font-size: 2rem;
}

.icon-para-sel {
    color: #0063F5;
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 4px;
    margin-left: 10px;
}

.sidebar {
    padding-top: 30px;
}

/* ---------------- Media Queries ------------------------------ */

@media only screen and (max-width:1362px) and (min-width:1276px) {
    .logo-sidebar img {
        /* background-image: url("../assets/hermoso_logo.png"); */
        width: 14rem;
        height: 6rem;
    }

}

@media only screen and (max-width:1275px) and (min-width:533px) {
    .logo-sidebar img {
        width: 7rem;
        height: 4rem;
    }

    .text-icon {
        display: flex;
        justify-content: center;
    }

    .text-icon-sel {
        display: flex;
        justify-content: center;
    }

    .main-div-sidebar {
        width: 100%;
        min-height: 100vh;
        background-color: #fff;
        border-right: 3px solid #FCFCFF;
    }


    .icon-para {
        display: none;
    }

    .icon-para-sel {
        display: none;
    }
}

@media only screen and (max-width:532px) and (min-width:425px) {
    .logo-sidebar img {
        width: 6rem;
        height: 3rem;
    }

    .text-icon {
        display: flex;
        justify-content: center;
    }

    .text-icon-sel {
        display: flex;
        justify-content: center;
    }

    .icon-para {
        display: none;
    }

    .icon-para-sel {
        display: none;

    }
}

@media only screen and (max-width:424px) and (min-width:280px) {
    .logo-sidebar img {
        width: 4rem;
        height: 2rem;
    }

    .text-icon {
        display: flex;
        justify-content: center;
    }

    .text-icon-sel {
        display: flex;
        justify-content: center;
    }

    .icon-para {
        display: none;
    }

    .icon-para-sel {
        display: none;
    }

    .side-bar-icon-sel {
        font-size: 1.5rem;
    }

    .side-bar-icon {
        font-size: 1.5rem;
        color: #C7C7C7;
    }
}