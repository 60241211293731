.account-top {
    background-color: #fff;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding: 2%;
    margin-bottom: 5%;
}

.account-para {
    font-size: 2.1rem;
    font-weight: 600;
    color: black;
}

.filter-btn button {
    background-color: transparent;
    width: 87px;
    height: 30px;
    border: 0.5px solid #0063F5;
    border-radius: 5px;
    color: #0063F5;
    font-size: 12px;
    padding-left: 1%;
    padding-right: 1%;
}

.customer-account-table .Btn {
    background-color: transparent;
    width: 67px;
    height: 30px;
    font-size: 12px;
    border: 1px solid #0063F5;
    border-radius: 5px;
    color: #0063F5;
    font-weight: 700;
    margin-left: 20px;
}

.serviceProvider-account-table .Btn1 {
    background-color: transparent;
    width: 67px;
    height: 30px;
    border: 1px solid #0063F5;
    border-radius: 5px;
    font-size: 12px;
    color: #0063F5;
    font-weight: 700;
    margin-left: 20px;
}

.serviceProvider-account-table .Btn4 {
    background-color: #0063F5;
    width: 120px;
    height: 30px;
    border: 1px solid #0063F5;
    border-radius: 5px;
    font-size: 12px;
    color: white;
    font-weight: 700;
    margin-left: 20px;
}

.btn0-txt {
    font-size: 12px;
    color: white;
    font-weight: 700;
}

.serviceProvider-account-table .Btn0 {
    background-color: #0063F5;
    width: 67px;
    height: 30px;
    border: 1px solid #0063F5;
    border-radius: 5px;
    font-size: 12px;
    color: white;
    font-weight: 700;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.serviceProvider-account-table button:hover {
    background-color: #0063F5;
    color: #fff;

}

.customer-account-table button:hover {
    background-color: #0063F5;
    color: #fff;
}

.filter-btn button:hover {
    background-color: #0063F5;
    color: #fff;
}

.account-icon {
    height: 30px;
    width: 50px;
    background-color: #0063F5;
    color: #fff;
    font-size: 2.5rem;
    border-radius: 5px;
    border: 0.5px solid #0063F5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.account-icon:hover {
    background-color: transparent;
    color: #0063F5;
}

.account-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;
}

.account-button {
    display: flex;
    align-items: center;
}

.thirty-days {
    font-size: 12px;
    color: #404040;
    font-weight: 500;
    padding-top: 4%;
    padding-bottom: 4%;
}

.cross-icon {
    font-size: 2rem;
    margin-left: 15px;
    color: #404040;
    margin-top: 5px;
}

.cross-icon1 {
    font-size: 1.8rem;
    margin-left: auto;
    color: #404040;
    margin-top: 5px;
}

.last-thirty-days {
    display: flex;
    align-items: center;
    background: rgba(199, 199, 199, 0.3);
    border-radius: 5px;
    width: 220px;
    height: 30px;
    padding: 5px;
}

.selectBtn {
    width: 67px;
    height: 30px;
    border: 1px solid #0063F5;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    margin-left: 20px;
    background-color: transparent;
    color: #0063F5;
}

.selectBtn1 {
    width: 67px;
    height: 30px;
    border: 1px solid #0063F5;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    margin-left: 20px;
    background-color: #0063F5;
    color: #fff;

}

.selectBtn2 {
    width: 67px;
    height: 30px;
    border: 1px solid #0063F5;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    margin-left: 20px;
    background-color: #0063F5;
    color: #fff;
}

.selectBtn3 {
    width: 67px;
    height: 30px;
    border: 1px solid #0063F5;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    margin-left: 20px;
    background-color: #0063F5;
    color: #fff;
}

.selectBtn4 {
    width: 67px;
    height: 30px;
    border: 1px solid #0063F5;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    margin-left: 20px;
    background-color: #0063F5;
    color: #fff;
}


/* -------------------- Media Queries --------------------------------- */


@media only screen and (max-width:375px) and (min-width:279px) {
    .filter-btn button {
        background-color: transparent;
        width: 25px;
        height: 10px;
        border: 0.5px solid #0063F5;
        border-radius: 2px;
        color: #0063F5;
        font-size: 4px;
        margin-left: 10px;
    }

    .checkcheck {
        width: 20px !important;
        height: 17px !important;
    }

    .checkcheck2 {
        width: 13px !important;
        height: 17px !important;
    }

    .myicon {
        width: 5px !important;
        height: 5px !important;
    }

    .customer-account-table .Btn {
        background-color: transparent;
        width: 50px;
        height: 10px;
        font-size: 4px;
        border: 1px solid #0063F5;
        border-radius: 2px;
        color: #0063F5;
        font-weight: 700;
        margin-left: 20px;
    }

    .serviceProvider-account-table .Btn1 {
        background-color: transparent;
        width: 50px;
        height: 10px;
        border: 1px solid #0063F5;
        border-radius: 2px;
        font-size: 4px;
        color: #0063F5;
        font-weight: 700;
        margin-left: 20px;
    }

    .serviceProvider-account-table .Btn4 {
        background-color: #0063F5;
        width: 67px;
        height: 10px;
        border: 1px solid #0063F5;
        border-radius: 2px;
        font-size: 4px;
        color: white;
        font-weight: 700;
        margin-left: 20px;
    }

    .btn0-txt {
        font-size: 4px;
        color: white;
        font-weight: 700;
    }

    .serviceProvider-account-table .Btn0 {
        background-color: #0063F5;
        width: 50px;
        height: 10px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        color: white;
        font-weight: 700;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .serviceProvider-account-table button:hover {
        background-color: #0063F5;
        color: #fff;

    }

    .customer-account-table button:hover {
        background-color: #0063F5;
        color: #fff;
    }

    .filter-btn button:hover {
        background-color: #0063F5;
        color: #fff;
    }

    .account-icon {
        height: 10px;
        width: 15px;
        background-color: #0063F5;
        color: #fff;
        font-size: 8px;
        border-radius: 2px;
        border: 0.5px solid #AEAEAE;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        margin-top: 2px;
    }

    .account-icon:hover {
        background-color: transparent;
        color: #0063F5;
    }

    .account-flex {
        display: block;
    }

    .account-button {
        display: flex;
        align-items: center;
    }

    .thirty-days {
        font-size: 4px;
        color: #404040;
        font-weight: 500;
    }

    .cross-icon {
        font-size: 0.6rem;
        margin-left: 15px;
        color: #404040;
        margin-top: 2px;
    }

    .cross-icon1 {
        font-size: 0.5rem;
        margin-left: auto;
        color: #404040;
        margin-top: 2px;
    }

    .last-thirty-days {
        display: flex;
        align-items: center;
        background: rgba(199, 199, 199, 0.3);
        border-radius: 2px;
        width: 90px;
        height: 10px;
        padding: 5px;
    }

    .selectBtn {
        width: 50px;
        height: 10px;
        border: 1px solid #0063F5;
        border-radius: 2px;
        font-size: 4px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn1 {
        width: 50px;
        height: 10px;
        border: 1px solid #0063F5;
        border-radius: 2px;
        font-size: 4px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn2 {
        width: 67px;
        height: 30px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn3 {
        width: 67px;
        height: 30px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn4 {
        width: 67px;
        height: 30px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

}


@media only screen and (max-width:400px) and (min-width:374px) {
    .account-para {
        font-size: 1rem;
        font-weight: 600;
        color: #0063F5;
        margin-bottom: 2%;
    }

    .filter-btn button {
        background-color: transparent;
        width: 25px;
        height: 13px;
        border: 0.5px solid #0063F5;
        border-radius: 3px;
        color: #0063F5;
        font-size: 4px;
        margin-left: 20px;
    }

    .customer-account-table .Btn {
        background-color: transparent;
        width: 52px;
        height: 13px;
        font-size: 5px;
        border: 1px solid #0063F5;
        border-radius: 3px;
        color: #0063F5;
        font-weight: 700;
        margin-left: 20px;
    }

    .serviceProvider-account-table .Btn1 {
        background-color: transparent;
        width: 55px;
        height: 13px;
        border: 1px solid #0063F5;
        border-radius: 3px;
        font-size: 5px;
        color: #0063F5;
        font-weight: 700;
        margin-left: 20px;
    }

    .serviceProvider-account-table .Btn4 {
        background-color: #0063F5;
        width: 58px;
        height: 10px;
        border: 1px solid #0063F5;
        border-radius: 2px;
        font-size: 4px;
        color: white;
        font-weight: 700;
        margin-left: 20px;
    }

    .btn0-txt {
        font-size: 4px;
        color: white;
        font-weight: 700;
    }

    .serviceProvider-account-table .Btn0 {
        background-color: #0063F5;
        width: 55px;
        height: 13px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        color: white;
        font-weight: 700;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .selectBtn {
        width: 52px;
        height: 13px;
        border: 1px solid #0063F5;
        border-radius: 3px;
        font-size: 5px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn1 {
        width: 55px;
        height: 13px;
        border: 1px solid #0063F5;
        border-radius: 3px;
        font-size: 5px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn2 {
        width: 67px;
        height: 30px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn3 {
        width: 67px;
        height: 30px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn4 {
        width: 67px;
        height: 30px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .account-icon {
        height: 13px;
        width: 20px;
        background-color: #0063F5;
        color: #fff;
        font-size: 0.9rem;
        border-radius: 3px;
        border: 0.5px solid #AEAEAE;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
    }

    .thirty-days {
        font-size: 5px;
        color: #404040;
        font-weight: 500;
    }

    .account-flex {
        display: block;
    }

    .cross-icon {
        font-size: 0.9rem;
        margin-left: 10px;
        color: #404040;
        margin-top: 3px;
    }

    .cross-icon1 {
        font-size: 1rem;
        margin-left: auto;
        color: #404040;
        margin-top: 5px;
    }

    .last-thirty-days {
        display: flex;
        align-items: center;
        background: rgba(199, 199, 199, 0.3);
        border-radius: 3px;
        width: 100px;
        height: 13px;
        padding: 5px;
    }

}





@media only screen and (max-width:600px) and (min-width:401px) {
    .account-para {
        font-size: 1.5rem;
        font-weight: 600;
        color: #0063F5;
        margin-bottom: 2%;
    }

    .filter-btn button {
        background-color: transparent;
        width: 35px;
        height: 15px;
        border: 0.5px solid #0063F5;
        border-radius: 3px;
        color: #0063F5;
        font-size: 6px;
        margin-left: 20px;
    }

    .customer-account-table .Btn {
        background-color: transparent;
        width: 40px;
        height: 15px;
        font-size: 7px;
        border: 1px solid #0063F5;
        border-radius: 3px;
        color: #0063F5;
        font-weight: 700;
        margin-left: 20px;
    }

    .serviceProvider-account-table .Btn1 {
        background-color: transparent;
        width: 40px;
        height: 15px;
        border: 1px solid #0063F5;
        border-radius: 3px;
        font-size: 7px;
        color: #0063F5;
        font-weight: 700;
        margin-left: 20px;
    }

    .serviceProvider-account-table .Btn4 {
        background-color: #0063F5;
        width: 43px;
        height: 10px;
        border: 1px solid #0063F5;
        border-radius: 2px;
        font-size: 4px;
        color: white;
        font-weight: 700;
        margin-left: 20px;
    }

    .btn0-txt {
        font-size: 4px;
        color: white;
        font-weight: 700;
    }

    .serviceProvider-account-table .Btn0 {
        background-color: #0063F5;
        width: 40px;
        height: 15px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        color: white;
        font-weight: 700;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .selectBtn {
        width: 40px;
        height: 15px;
        border: 1px solid #0063F5;
        border-radius: 3px;
        font-size: 7px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn1 {
        width: 40px;
        height: 15px;
        border: 1px solid #0063F5;
        border-radius: 3px;
        font-size: 7px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn2 {
        width: 67px;
        height: 30px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn3 {
        width: 67px;
        height: 30px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .selectBtn4 {
        width: 67px;
        height: 30px;
        border: 1px solid #0063F5;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        margin-left: 20px;
        background-color: #0063F5;
        color: #fff;
    }

    .account-icon {
        height: 15px;
        width: 25px;
        background-color: #0063F5;
        color: #fff;
        font-size: 1.1rem;
        border-radius: 3px;
        border: 0.5px solid #AEAEAE;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
    }

    .account-flex {
        display: block;
    }


    .thirty-days {
        font-size: 7px;
        color: #404040;
        font-weight: 500;
    }

    .cross-icon {
        font-size: 1.1rem;
        margin-left: 10px;
        color: #404040;
        margin-top: 3px;
    }

    .cross-icon1 {
        font-size: 1rem;
        margin-left: auto;
        color: #404040;
        margin-top: 5px;
    }

    .last-thirty-days {
        display: flex;
        align-items: center;
        background: rgba(199, 199, 199, 0.3);
        border-radius: 3px;
        width: 130px;
        height: 15px;
        padding: 5px;
    }

}

@media only screen and (max-width:810px) and (min-width:601px) {
    .account-flex {
        display: block;
    }

    .account-para {
        margin-bottom: 2%;
    }
}

.manage-flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.manage-button {
    display: flex;
    flex-wrap: wrap;

}

input[type="checkbox"] {
    background: #FFFFFF;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid #0063F5;
    /* margin-right: 7%; */
    width: 20px;
    height: 20px;

}

.myicon {
    width: 12px;
    height: 12px;
}

.custom-radio-btn {
    margin-top: 1%;
    margin-right: 1%;
    width: 17px;
    height: 17px;
    border: 1.5px solid #292d32;
    border-radius: 6px;
    /* background-image: url(/src/assets/Vector.png);
background-repeat: no-repeat; */
    justify-content: center;
    display: flex;
    align-items: center;
}

.footer-bottom {
    clear: both;
    position: absolute;
    bottom: 0;
    width: 100%;

}

.footer-bottom2 {
    clear: both;
    position: absolute;
    bottom: 0;
    width: 83%;
}

.date {
    background: transparent;
    border: none;
    width: 100%;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}