.super-heading {
    font-size: 16px;
    font-weight: 500;

}

.lastupdate {
    margin-top: 1%;
    font-size: 12px;
}

.textdata {
    margin-top: 2%;
    font-size: 14px;
}

.textdata2 {
    margin-top: 2%;
    font-size: 14px;
    margin-left: 5%;
}

.super-heading2 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 2%;
}

.blue-heading {
    font-size: 16px;
    font-weight: 500;
    color: blue;
    margin-top: 2%;
}