select {
    width: 100%;
    height: 4rem;
    border: 1px solid #E3E6EF;
    padding: 10px;
    color: #5A5F7D;
    font-weight: 14px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 10px;
    margin-top: 4%;
}

.option {
    /* font-size: 14px; */
    background-color: transparent;
}