.background-pattern {
    min-height: 100vh;
    width: 100%;
    background-color: #F8FAFC;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-main {
    text-align: left;
    font-size: 25px;
    font-weight: 500;
    margin-top: 5%;
}

.email {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
}

.Login-center {
    width: 45%;
    min-height: 370px;
    background-color: white;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.19);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2%;
    justify-content: center;
}

.login-main {
    text-align: left;
    font-size: 25px;
    font-weight: 500;
    margin-top: 5%;
}