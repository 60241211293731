.timePickerStyle > div > input {
    border: 1px solid #E3E6EF;
    font-size: 14px;
    font-weight: 300;
    color: #5A5F7D;
    padding-left: 15px;
    padding: 0;
    padding-left: 10px;
    margin: 0;
}

.timePickerStyle > div:before {
    content: none;
}

.timePickerStyle > div:after {
    content: none;
}

.cancel div {
    padding: 7px 10px;
    margin-bottom: 20px;
    border: 1px solid gray;
    background-color: transparent;
    color: black;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
}

.cancel div:hover {
    background-color: #f1f1f1;
}

.cancel div {
    width: 100px;
    height: 45px;
    margin-bottom: 20px;
    border: 1px solid black;
    background-color: transparent;
    color: black;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    display: flex;
}