.modal-main input {
    height: 40px;
    width: 100%;
    padding-left: 5px;
    border: 1px solid #E3E6EF;
    outline: none;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 300;
    color: #5A5F7D;
    padding-left: 15px;
}

.modal-main input::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: #5A5F7D;
}

.MuiDialog-paperWidthSm {
    width: 60rem;
}

.modal-main label {
    font-size: 12px;
    font-weight: 500;
    color: #272B41;
}



.line {
    width: 100%;
    height: 1px;
    background-color: #F1F2F6;
    margin-top: -10px;
    margin-bottom: 15px;
}

/* .close-btn{
    font-size: 2.5rem;
    color: #EA0606;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 10px;
} */

.save-changes button {
    width: 100px;
    height: 45px;
    margin-bottom: 20px;
    border: none;
    background-color: #0063F5;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
}

.save-changes button:hover {
    background-color: #0063F5;
}

.close-btn:hover {
    color: #0063F5;
}

.cancel button {
    width: 100px;
    height: 45px;
    margin-bottom: 20px;
    border: 1px solid #E3E6EF;
    background-color: transparent;
    color: #9299B8;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
}

.cancel button:hover {
    background-color: #fefdfd;
}

.but-flex {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
}

h2.h2-head {
    font-size: 24px;
    color: #272B41;
    font-weight: 700
}

.close-btn {
    font-size: 3rem;
    color: #0063F5;
}

.img-area {
    width: 100%;
    height: 23.3rem;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #E3E6EF;
    position: relative;
}

.img-area-1 {
    width: 100%;
    height: 15rem;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #E3E6EF;
    position: relative;
}

.img-area-2 {
    width: 100%;
    height: 15rem;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #E3E6EF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo img {
    width: 64px;
    height: 50px;
    margin-bottom: 20px;
    margin-left: 5px;
}

.photo1 img {
    width: 60px;
    height: 31px;
    margin-bottom: 5px;
    margin-left: 5px;
}

.photo2 img {
    width: 120px;
    height: 80px;
    margin-bottom: 5px;
    margin-left: 5px;
}

p.drag {
    font-size: 14px;
    font-weight: 600;
    color: #898989;
}

.photo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid red; */
    width: 163px;
    height: 124px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;
}

.photo1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 163px;
    height: 60px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

.img-src-btn label {
    width: 156px;
    height: 33px;
    background: transparent;
    border: 1px solid #0063F5;
    color: #0063F5;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-src-btn1 label {
    width: 156px;
    height: 33px;
    background: transparent;
    border: 1px solid #0063F5;
    color: #0063F5;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-src-btn {
    display: flex;
    justify-content: center;
}

.img-src-btn1 {
    display: flex;
    justify-content: center;
}

.img-src-btn button:hover {
    background-color: #e8e8e867;
}

.img-src-btn1 button:hover {
    background-color: #e8e8e867;
}

.img-src-btn input {
    display: none;
}

.img-src-btn1 input {
    display: none;
}

.show-img {
    position: absolute;
    width: 60px;
    height: 50px;
    display: block;
    margin-right: 10px;
}

.show-img img {
    width: 60px;
    height: 55px;
    border-radius: 10px 10px 10px 10px;
}

.opacit[opa="1"] {
    opacity: 1;
}

.opacit[opa="0"] {
    opacity: 0;
}

.pic-del {
    color: #0063F5;
    position: absolute;
    font-size: 3rem;
    top: -13px;
    right: -18px;
}

.pic-del:hover {
    color: #0063F5;
}

.input-flex {
    display: flex;
    justify-content: space-between;
}

.input-flex-des label {
    display: block;
    margin-bottom: 10px;
}

.input-flex-des textarea {
    width: 100%;
    height: 12rem;
    border: 1px solid #E3E6EF;
    padding: 10px;
    color: #5A5F7D;
    font-weight: 14px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 10px;
}

.input-flex-des textarea:focus {
    outline: none;
}