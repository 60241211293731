.background-pattern {
    min-height: 100vh;
    width: 100%;
    background-color: #FCFCFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Login-center {
    width: 45%;
    min-height: 370px;
    background-color: #FCFCFF;

    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2%;
    justify-content: center;
}

.login-main {
    text-align: left;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    margin-top: 8%;
}

.email {
    text-align: left;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    margin-top: 4%;
    margin-bottom: 2%;
}

.register-input {
    width: 100%;
    height: 40px;
    border: 1px solid #E3E6EF;
    margin-bottom: 3%;
    font-size: 15px;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 3px;
}

.signup-btn {
    width: 100%;
    height: 40px;
    background-color: #0063F5;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.signup-text {
    color: white;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
}

.line-grey {
    width: 100%;
    min-width: 100px;
    max-width: 250px;
    margin-left: 2%;
    margin-right: 2%;
    height: 1.5px;
    background: rgb(177, 173, 173);
    margin-top: 2.8%;
}

.selectlanguage {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: black;
    text-align: center;

}

@media only screen and (max-width:650px) {
    .Login-center {
        width: 100%;
    }
}