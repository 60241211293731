label {
  font-size: 15px;
  width: 100%;
}

.BtnSubmit {
  border-radius: 8px;
  border: 1px solid #878282dd;
  background-color: #367939;
  box-shadow: rgba(1, 1, 1, 1) 0px 1px 11px -6px;
  margin-top: 10px;
  width: 200px;
  margin-left: auto;
  color: white;
  height: 26px;
  font-weight: bold;
}

.filters {
  width: 99%;
  margin-bottom: 10px;
  border: 1px solid #878282dd;
  border-radius: 8px;
  background: #f8f8f8;
  box-shadow: rgba(1, 1, 1, 1) 0px 1px 11px -6px;
  padding: 10px;
  cursor: pointer;
}
