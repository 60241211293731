@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

*, *::before, *::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    font-family: 'Raleway', sans-serif;
    font-size: 62.5%;
}

.main-div{
    width: 93%;
    margin: 0 auto;
    margin-bottom: 2%;
}
.main-div1{
    width: 93%;
    margin: 0 auto;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
}

body{
    background-color: #FCFCFF;
}