label {
  font-size: 15px;
  width: 100%;
}

input,
.input {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #878282dd;
  border-radius: 8px;
  background: #f8f8f8;
  box-shadow: rgba(1, 1, 1, 1) 0px 1px 11px -6px;
  padding: 10px;
}

.BtnRemove {
  border-radius: 100px;
  border: 1px solid #878282dd;
  background-color: #dc3939;
  box-shadow: rgba(1, 1, 1, 1) 0px 1px 11px -6px;
  margin-left: 5px;
  width: 32px;
  height: 32px;
  font-weight: bold;
}

.BtnAdd {
  border-radius: 8px;
  border: 1px solid #878282dd;
  background-color: #0099ff;
  box-shadow: rgba(1, 1, 1, 1) 0px 1px 11px -6px;
  margin-top: 10px;
  width: 250px;
  height: 22px;
  font-weight: bold;
  margin-left: auto;
}

.BtnSubmit {
  border-radius: 8px;
  border: 1px solid #878282dd;
  background-color: #367939;
  box-shadow: rgba(1, 1, 1, 1) 0px 1px 11px -6px;
  margin-top: 10px;
  width: 90%;
  color: white;
  height: 44px;
  font-weight: bold;
}
