.main-div-header {
    width: 93%;
    margin: 0 auto;
    margin-bottom: 2.5%;
}

.heading {
    font-size: 4rem;
    font-weight: 600;
}


.header-flex {
    margin-top: 3.8%;
}

.header-para {
    font-size: 14px;
    margin-top: 10px;
    color: #AEAEAE;
}

.search-header input {
    width: 22rem;
    height: 3rem;
    border-radius: 20px;
    border: 1px solid #f1f1f1;
    outline: none;
    padding-left: 28px;
    color: #8E8E8E;
}

.search-header input::placeholder {
    color: #8E8E8E;
    font-size: 13px;
}

.search-header {
    position: relative;
}

.search-icon {
    font-size: 2rem;
    color: #6E6E6E;
    position: absolute;
    top: 5px;
    left: 5px;
}

.profile img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: inline-block;
}

.profile {
    display: flex;
}

.profile-text {
    margin-top: 10px;
    margin-left: 15px;
}

.header-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.person-name {
    font-size: 13px;
    color: #404040;
    font-weight: 600;
}

.person-admin {
    font-size: 13px;
    color: #AEAEAE;
}

/* --------------------- Media Queries ------------------ */

@media only screen and (max-width:1140px) and (min-width:999px) {

    .heading {
        font-size: 3.5rem;
        font-weight: 600;
    }

    .header-flex {
        margin-top: 33px;
    }

    .header-para {
        font-size: 12px;
        margin-top: 10px;
        color: #AEAEAE;
    }

    .search-header input {
        width: 20rem;
        height: 2.8rem;
        border-radius: 20px;
        border: 1px solid #f1f1f1;
        outline: none;
        padding-left: 28px;
        color: #8E8E8E;
    }

    .search-header input::placeholder {
        color: #8E8E8E;
        font-size: 13px;
    }

    .search-header {
        position: relative;
    }

    .search-icon {
        font-size: 1.8rem;
        color: #6E6E6E;
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .profile img {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        display: inline-block;
    }

    .profile {
        display: flex;
    }

    .profile-text {
        margin-top: 10px;
        margin-left: 15px;

    }
    .person-name{
        font-size: 12px;
        color: #404040;
        font-weight: 600;
    }
    
    .person-admin{
        font-size: 12px;
        color: #AEAEAE;
    }
}

@media only screen and (max-width:998px) and (min-width:887px) {

    .heading {
        font-size: 3rem;
        font-weight: 600;
    }

    .header-flex {
        margin-top: 33px;
    }

    .header-para {
        font-size: 12px;
        margin-top: 10px;
        color: #AEAEAE;
    }

    .search-header input {
        width: 18rem;
        height: 2.5rem;
        border-radius: 20px;
        border: 1px solid #f1f1f1;
        outline: none;
        padding-left: 28px;
        color: #8E8E8E;
    }

    .search-header input::placeholder {
        color: #8E8E8E;
        font-size: 12px;
    }

    .search-header {
        position: relative;
    }

    .search-icon {
        font-size: 1.5rem;
        color: #6E6E6E;
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .profile img {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        display: inline-block;
    }

    .profile {
        display: flex;
    }

    .profile-text {
        margin-top: 5px;

    }
    .person-name{
        font-size: 11px;
        color: #404040;
        font-weight: 600;
    }
    
    .person-admin{
        font-size: 11px;
        color: #AEAEAE;
    }
}

@media only screen and (max-width:887px) and (min-width:741px) {

    .heading {
        font-size: 2.7rem;
        font-weight: 600;
    }

    .header-flex {
        margin-top: 33px;
    }

    .header-para {
        font-size: 11px;
        margin-top: 5px;
        color: #AEAEAE;
    }

    .search-header input {
        width: 15rem;
        height: 2.1rem;
        border-radius: 20px;
        border: 1px solid #f1f1f1;
        outline: none;
        padding-left: 28px;
        color: #8E8E8E;
        font-size: 11px;
    }

    .search-header input::placeholder {
        color: #8E8E8E;
        font-size: 11px;
    }

    .search-header {
        position: relative;
    }

    .search-icon {
        font-size: 1.3rem;
        color: #6E6E6E;
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .profile img {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        display: inline-block;
    }

    .profile {
        display: flex;
    }

    .profile-text {
        margin-top: 5px;

    }
    .person-name{
        font-size: 8px;
        color: #404040;
        font-weight: 600;
    }
    
    .person-admin{
        font-size: 8px;
        color: #AEAEAE;
    }
}

@media only screen and (max-width:742px) and (min-width:562px) {

    .heading {
        font-size: 2.1rem;
        font-weight: 600;
    }

    .header-flex {
        margin-top: 33px;
    }

    .header-para {
        font-size: 9px;
        margin-top: 5px;
        color: #AEAEAE;
    }

    .search-header input {
        width: 10rem;
        height: 1.6rem;
        border-radius: 20px;
        border: 1px solid #f1f1f1;
        outline: none;
        padding-left: 18px;
        color: #8E8E8E;
        font-size: 8px;
    }

    .search-header input::placeholder {
        color: #8E8E8E;
        font-size: 8px;
    }

    .search-header {
        position: relative;
    }

    .search-icon {
        font-size: 0.9rem;
        color: #6E6E6E;
        position: absolute;
        top: 3px;
        left: 5px;
    }

    .profile img {
        width: 25px !important;
        height: 25px !important;
        border-radius: 100%;
        display: inline-block;
    }

    .profile {
        display: flex;
    }

    .profile-text {
        margin-top: 5px;
        margin-left: 5px;

    }
    .person-name{
        font-size: 6px;
        color: #404040;
        font-weight: 600;
    }
    
    .person-admin{
        font-size: 6px;
        color: #AEAEAE;
    }
}

/* @media only screen and (max-width:612px) and (min-width:560px){
    .search-header input {
        width: 9rem;
        height: 1.6rem;
        border-radius: 20px;
        border: 1px solid #f1f1f1;
        outline: none;
        padding-left: 18px;
        color: #8E8E8E;
        font-size: 8px;
    }

    .search-header input::placeholder {
        color: #8E8E8E;
        font-size: 8px;
    }
} */

@media only screen and (max-width:780px) and (min-width:741px){
    .search-header input {
        width: 10rem;
        height: 1.6rem;
        border-radius: 20px;
        border: 1px solid #f1f1f1;
        outline: none;
        padding-left: 18px;
        color: #8E8E8E;
        font-size: 8px;
    }

    .search-header input::placeholder {
        color: #8E8E8E;
        font-size: 8px;
    }

    .search-icon {
        font-size: 0.9rem;
        color: #6E6E6E;
        position: absolute;
        top: 3px;
        left: 5px;
    }
}

@media only screen and (max-width:562px) and (min-width:376px){

    .heading {
        font-size: 1.5rem;
        font-weight: 600;
    }

    .header-flex {
        margin-top: 33px;
    }

    .header-para {
        font-size: 6px;
        margin-top: 3px;
        color: #AEAEAE;
    }

    .search-header input {
        width: 5rem;
        height: 1rem;
        border-radius: 20px;
        border: 1px solid #f1f1f1;
        outline: none;
        padding-left: 12px;
        color: #8E8E8E;
        font-size: 4.5px;
    }

    .search-header input::placeholder {
        color: #8E8E8E;
        font-size: 4px;
    }

    .search-header {
        position: relative;
    }

    .search-icon {
        font-size: 0.6rem;
        color: #6E6E6E;
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .profile img {
        width: 20px !important;
        height: 20px !important;
        border-radius: 100%;
        display: inline-block;
    }

    .profile {
        display: flex;
    }

    .profile-text {
        margin-top: 2px;
        margin-left: 5px;

    }
    .person-name{
        font-size: 4px;
        color: #404040;
        font-weight: 600;
    }
    
    .person-admin{
        font-size: 4px;
        color: #AEAEAE;
    }
}

@media only screen and (max-width:613px) and (min-width:562px){
    .search-header input {
        width: 8rem;
        height: 1.5rem;
        border-radius: 20px;
        border: 1px solid #f1f1f1;
        outline: none;
        padding-left: 12px;
        color: #8E8E8E;
        font-size: 5px;
    }

    .search-header input::placeholder {
        color: #8E8E8E;
        font-size: 5px;
    }
    
    .search-icon {
        font-size: 0.6rem;
        color: #6E6E6E;
        position: absolute;
        top: 5px;
        left: 5px;
    }

}

@media only screen and (max-width:376px) and (min-width:279px){

    .heading {
        font-size: 1.5rem;
        font-weight: 600;
    }

    .header-flex {
        margin-top: 33px;
    }

    .header-para {
        font-size: 4px;
        margin-top: 3px;
        color: #AEAEAE;
    }

    .search-header input {
        width: 5rem;
        height: 0.8rem;
        border-radius: 20px;
        border: 1px solid #f1f1f1;
        outline: none;
        padding-left: 12px;
        color: #8E8E8E;
        font-size: 4px;
    }

    .search-header input::placeholder {
        color: #8E8E8E;
        font-size: 4px;
    }

    .search-header {
        position: relative;
    }

    .search-icon {
        font-size: 0.5rem;
        color: #6E6E6E;
        position: absolute;
        top: 6px;
        left: 5px;
    }

    .profile img {
        width: 14px !important;
        height: 14px !important;
        border-radius: 100%;
        display: inline-block;
    }

    .profile {
        display: flex;
    }

    .profile-text {
        margin-top: 2px;
        margin-left: 5px;

    }
    .person-name{
        font-size: 4px;
        color: #404040;
        font-weight: 600;
    }
    
    .person-admin{
        font-size: 4px;
        color: #AEAEAE;
    }
}