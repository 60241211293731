.modal-main input {
    height: 40px;
    padding-left: 5px;
    width: 100%;
    outline: none;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 14px;
}

.modal-main label {
    font-size: 14px;
    font-weight: 600;
}

.drop label {
    font-size: 14px;
    font-weight: 600;
}

.close-btn {
    font-size: 2.5rem;
    color: #EA0606;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 10px;
}

.close-btn:hover {
    font-size: 2.5rem;
    color: #EA0606;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 10px;
}

.save-changes button {
    padding: 8px 10px;
    margin-bottom: 20px;
    border: none;
    background-color: #0063F5;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.save-changes button:hover {
    background-color: #0063F5;
}

.cancel button {
    padding: 7px 10px;
    margin-bottom: 20px;
    border: 1px solid gray;
    background-color: transparent;
    color: black;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
}

.cancel button:hover {
    background-color: #f1f1f1;
}

.but-flex {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
}